import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const Network = () => {
    return (
        <div className="background background-network">
            <div className="overlay">
                <div className="container">
                    <Header />
                    <main>
                    <h1>Nettverk</h1>
                        <div>
                            Vi setter opp nettverk for din bedrift!<br />
                            Med vårt topp moderne utstyr fra produsenter som UniFi, våre skybaserte løsninger og kompetente team finner vi en løsning som passer for din bedrift.
                            <br /><br />
                            Hos oss får du:<br />
                            Oppsett og nettverkdesign tilpasset din bedrift.<br />
                            Gode supporttjenester.<br />
                            Konkurransedyktige priser.<br />
                            Oversiktlige fakturaer.<br />
                            <br />
                            Ønsker du et tilbud? Benytt <Link to="/contact">kontaktsskjemaet</Link> så finner vi en løsning som passer deg!
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Network;