import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';

const Announcement = (props) => {

    const [article, setArticle] = useState();
    const [loading, setLoading] = useState(true);
    const [foundArticle, setFound] = useState(false);

    useEffect(() => {
        (async () => {
            const result = await fetch("https://norskhosting.no/api/?a=announcements");
            const json = await result.json();
            let id = 0;
            if(props.match.params.id){
                id = parseInt(props.match.params.id);
            }
            if(json.announcements){
                json.announcements.announcement.forEach((article, index) => {
                    if(id === 0){
                        if(index === 0){
                            setArticle(article);
                            setFound(true);
                        }
                    }else if(id === article.id){
                        setArticle(article);
                        setFound(true);
                    }
                });
                setLoading(false);
            }
        })();
    }, [props.match.params.id]);

    return (
        <div className="background background-article">
            <div className="overlay">
                <div className="container">
                    <Header />
                    <main>
                        {loading ? <Loading /> : foundArticle ? (
                            <div className="align-left">
                                <h1>{article.title}</h1>
                                <div class="break"></div>
                                <div dangerouslySetInnerHTML={{__html: article.announcement}}></div>
                            </div>
                        ) : (
                            <>
                                <h1>Ikke funnet</h1>
                                <div class="break"></div>
                                <div>Artikkelen ble ikke funnet.</div>
                            </>
                        )}
                    </main>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Announcement;