import HttpsRedirect from 'react-https-redirect';
import { useEffect } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import Contact from './components/Contact';
import HomePage from './components/HomePage';
import Minecraft from './components/Minecraft';

import { loadReCaptcha } from 'react-recaptcha-v3'
import Network from './components/Network';
import Consultant from './components/Consultant';
import Announcement from './components/Announcement';

function App() {

  useEffect(() => {
    loadReCaptcha("6LfhgLYUAAAAANS26cfFpihl0QiPigByKUH03Git");
  }, []);

  return (
    <HttpsRedirect>
      <HashRouter>
        <Route exact path="/" component={HomePage} />
        <Route path="/minecraft" component={Minecraft} />
        <Route path="/contact" component={Contact} />
        <Route path="/network" component={Network} />
        <Route path="/announcement/:id" component={Announcement} />
        <Route path="/consultant" component={Consultant} />
        <Route path="/teamspeak" component={() => {
          window.location.href = "https://norskhosting.no/kunde/cart.php?a=confproduct&i=0";
          return null;
        }} />
        <Route path="/webhotel" component={() => {
          window.location.href = 'https://norskhosting.no/kunde/index.php?rp=/store/vanlige-webhotell';
          return null;
        }} />
        <Route path="/vps" component={() => {
          window.location.href = 'https://norskhosting.no/kunde/index.php?rp=/store/vps-virtual-private-server';
          return null;
        }} />
        <Route path="/buy/mc-turtle" component={() => {
          window.location.href = 'https://norskhosting.no/kunde/cart.php?a=add&pid=121';
          return null;
        }} />
        <Route path="/buy/mc-piglin" component={() => {
          window.location.href = 'https://norskhosting.no/kunde/cart.php?a=add&pid=146';
          return null;
        }} />
        <Route path="/buy/mc-dragon" component={() => {
          window.location.href = 'https://norskhosting.no/kunde/cart.php?a=add&pid=147';
          return null;
        }} />
      </HashRouter>
    </HttpsRedirect>
  );
}

export default App;
